/* eslint-disable no-console */
import React, { useContext } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { FeatureFlagsContext } from 'context/FeatureFlagsContext'
import { AtsMockProvider } from 'context/AtsMockContext'
import MockPage from './MockPage'
import LandingPage from './LandingPage'

const AtsHome = () => {
  const { jobId } = useParams()
  const { isFlagEnabled } = useContext(FeatureFlagsContext)
  const showDemoPage = isFlagEnabled('enable_ats_demo')

  return (
    <Box
      sx={{
        maxWidth: 1460,
        width: '90%',
        height: '100vh',
        padding: '40px 0',
        margin: '0 auto',
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {showDemoPage ? (
        <AtsMockProvider>
          <MockPage jobId={jobId || ''} />
        </AtsMockProvider>
      ) : (
        <LandingPage jobId={jobId || ''} />
      )}
    </Box>
  )
}
export default AtsHome
