/* eslint-disable no-console */
import React from 'react'
import {
  Box,
  Typography,
  Button,
  ButtonProps,
  styled,
  Stack,
  AlertColor
} from '@mui/material'
import { ArrowBack as ArrowIcon, Add as AddIcon } from '@mui/icons-material'
import { AdminContext } from 'context/AdminContext'
import { UserAdminContext } from 'context/UserAdminContext'
import { UserProfile } from 'model/organization'
import { getUsersFromEdges } from 'utils/adminData'
import LoadingIndicator from 'components/LoadingIndicator'
import SidePanel from 'components/SidePanel'
import AlertErrorMessage from 'components/AlertErrorMessage'
import { InviteUserContext } from 'context/InviteUserContext'
import UsersTable from './UsersTable'
import InviteUsers from '../InviteUsers'

const CreateButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#3148D3',
  color: '#FFFFFF',
  padding: '11px 20px 11px 13px',
  '&:hover': {
    backgroundColor: '#050B0F'
  }
}))

interface UsersPageProps {
  epId: string
}

const UsersPage = ({ epId }: UsersPageProps) => {
  const {
    getEmployerData,
    getEmployerError,
    getEmployerResponse,
    getEmployerIsLoading
  } = React.useContext(AdminContext)

  const {
    getUsers,
    getUsersError,
    getUsersResponse,
    getUsersIsLoading,
    getSelectedUser,
    selectedUser,
    selectedUserError,
    selectedUserIsLoading,
    changePermission,
    changePermissionSuccess,
    changePermissionError,
    changePermissionUnauthorized,
    deactivateUser,
    deactivateUserError,
    deactivateUserSuccess,
    deactivateUserUnauthorized,
    activateUser,
    activateUserError,
    activateUserSuccess,
    activateUserUnauthorized
  } = React.useContext(UserAdminContext)

  const [employer, setEmployer] = React.useState(getEmployerResponse)
  const [users, setUsers] = React.useState<UserProfile[]>([] as UserProfile[])
  const [inputs, setInputs] = React.useState<string[]>([''])
  const [emailIsEmpty, setEmailIsEmpty] = React.useState<boolean>(true)
  const [emailIsInvalid, setEmailIsInvalid] = React.useState<boolean>(true)
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)
  const [inviteErrorMessage, setInviteErrorMessage] = React.useState<string>('')
  const [openInviteAlert, setInviteOpenAlert] = React.useState<boolean>(false)
  const [inviteSeverity, setInviteSeverity] =
    React.useState<AlertColor>('error')
  const invitePanelRef = React.useRef<any>(null)

  const {
    inviteUsers,
    inviteSuccess,
    inviteError,
    inviteUnauthorized,
    setInviteSuccess,
    setInviteError,
    setInviteUnauthorized
  } = React.useContext(InviteUserContext)

  React.useMemo(() => {
    if (epId) {
      getEmployerData(epId)
      getUsers(epId)
    }
  }, [epId, getEmployerData, getUsers])

  React.useMemo(() => {
    if (getEmployerResponse) {
      setEmployer(getEmployerResponse)
    }
  }, [getEmployerResponse])

  React.useMemo(() => {
    if (getUsersResponse) {
      setUsers(
        getUsersFromEdges(getUsersResponse?.employerStaffAccounts?.edges)
      )
    }
  }, [getUsersResponse])

  React.useMemo(() => {
    if (inviteSuccess) {
      setInviteOpenAlert(true)
      setTimeout(() => {
        setInviteOpenAlert(false)
        setInviteSuccess(false)
      }, 5000)
      setInviteErrorMessage(
        'The invites were sent. Please have them check their emails.'
      )
      setInviteSeverity('success')
    }
  }, [inviteSuccess, setInviteSuccess])

  React.useMemo(() => {
    if (inviteError) {
      setInviteOpenAlert(true)
      setTimeout(() => {
        setInviteOpenAlert(false)
        setInviteError(false)
      }, 5000)
      setInviteErrorMessage('There was an error sending the invites.')
      setInviteSeverity('error')
    }
  }, [inviteError, setInviteError])

  React.useMemo(() => {
    if (inviteUnauthorized) {
      setInviteOpenAlert(true)
      setTimeout(() => {
        setInviteOpenAlert(false)
        setInviteUnauthorized(false)
      }, 5000)
      setInviteErrorMessage(
        'You are unauthorized to send invites to users within this Organization.'
      )
      setInviteSeverity('warning')
    }
  }, [inviteUnauthorized, setInviteUnauthorized])

  const submitRoleChanges = (
    employerId: string,
    userId: string,
    roles: string[]
  ) => {
    changePermission(userId, employerId, roles)
  }

  const handleDeactivateUser = (id: string, employerID: string) => {
    deactivateUser(id, employerID)
  }

  const closeInvitePanel = (onExitedCallback?: any) => {
    invitePanelRef.current?.closeSidePanel(onExitedCallback)
  }

  const handleInviteButtonClick = () => {
    // clear invite state if present
    setCurrentIndex(0)
    setEmailIsEmpty(false)
    setEmailIsInvalid(false)
    setInputs([''])

    // open panel
    invitePanelRef.current?.openSidePanel()
  }

  const handleInvite = (emails: string[], epCode: string) => {
    closeInvitePanel()

    // clear invite state
    setCurrentIndex(0)
    setEmailIsEmpty(false)
    setEmailIsInvalid(false)
    setInputs([''])

    // send emails to service
    const orgId = `EP::${epCode}`
    inviteUsers(emails, orgId)
  }

  return (
    <Box>
      {getEmployerIsLoading && !getEmployerError && !employer ? (
        <LoadingIndicator />
      ) : (
        <Box>
          <Button
            sx={{
              marginLeft: 'auto',
              marginRight: 0,
              marginBottom: '40px',
              borderRadius: 0,
              padding: '0 0 6px 0',
              '&:hover': {
                backgroundColor: 'transparent',
                borderBottom: '1px solid black',
                padding: '0 0 5px 0'
              }
            }}
            href='/admin-portal/'
            startIcon={<ArrowIcon />}
          >
            Back to Employer Admin Portal
          </Button>
          {getEmployerError ? (
            <Box>
              <Typography>
                There was an error retrieving the employer.
              </Typography>
            </Box>
          ) : (
            <Box>
              {employer ? (
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '20px 5px',
                    marginBottom: '40px'
                  }}
                >
                  <Typography variant='h1'>{employer?.name}</Typography>
                  {employer.status === 'active' && (
                    <CreateButton onClick={handleInviteButtonClick}>
                      <Stack alignItems='center' direction='row' gap={1}>
                        <AddIcon />
                        Add New User
                      </Stack>
                    </CreateButton>
                  )}
                </Box>
              ) : (
                <Box>
                  <Typography>The employer was not found.</Typography>
                </Box>
              )}
              {employer && employer?.status === 'active' ? (
                <Box>
                  <AlertErrorMessage
                    severity={inviteSeverity}
                    message={inviteErrorMessage}
                    openAlert={openInviteAlert}
                    setOpenAlert={setInviteOpenAlert}
                  />
                  <UsersTable
                    users={users}
                    error={getUsersError}
                    isLoading={getUsersIsLoading}
                    epId={epId}
                    submitRoleChanges={submitRoleChanges}
                    roleChangeError={changePermissionError}
                    roleChangeSuccess={changePermissionSuccess}
                    roleChangeUnauthorized={changePermissionUnauthorized}
                    deactivateUser={handleDeactivateUser}
                    deactivateUserError={deactivateUserError}
                    deactivateUserSuccess={deactivateUserSuccess}
                    deactivateUserUnauthorized={deactivateUserUnauthorized}
                    activateUser={activateUser}
                    activateUserSuccess={activateUserSuccess}
                    activateUserError={activateUserError}
                    activateUserUnauthorized={activateUserUnauthorized}
                    getSelectedUser={getSelectedUser}
                    selectedUser={selectedUser}
                    selectedUserError={selectedUserError}
                    selectedUserIsLoading={selectedUserIsLoading}
                  />
                  <SidePanel ref={invitePanelRef}>
                    <InviteUsers
                      epCode={employer?.code}
                      handleInvite={handleInvite}
                      setCurrentIndex={setCurrentIndex}
                      setEmailIsEmpty={setEmailIsEmpty}
                      setEmailIsInvalid={setEmailIsInvalid}
                      setInputs={setInputs}
                      inputs={inputs}
                      currentIndex={currentIndex}
                      emailIsEmpty={emailIsEmpty}
                      emailIsInvalid={emailIsInvalid}
                    />
                  </SidePanel>
                </Box>
              ) : (
                <Box>
                  <Typography>EP is currently inactive</Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
export default UsersPage
